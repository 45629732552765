@use 'variables'
@use 'mixins'

body, html
    max-width: 100vw
    height: auto
    overflow-x: hidden
    padding: 0
    margin: 0
    font-family: variables.$font-family-secondary

h1, button
    font-family: variables.$font-family-primary
    font-size: clamp(1.5rem, 5vw, 1.2rem)
    font-weight: variables.$font-weight-primary

ul li 
    list-style-type: circle
    color: variables.$dark-color

    :hover
        color: variables.$light-grey

a
    text-decoration: none
    color: variables.$dark-color

p
    @include mixins.text-alignment-md 

ul li, .footer-container h1, .footer-container h2, small
    font-family: variables.$font-family-primary
    font-size: variables.$font-size-bg
    color: variables.$dark-color

    :hover
        color: variables.$yellow


.navbar-container    
    @include mixins.flex-space-between
    width: 100%
    background-color: variables.$light-color
    height: 7rem
    padding: 2rem 
    position: fixed
    top: 0
    z-index: 5
    
    .logo-full 
        width: 12%

.navbar-container, .footer-container
    @include mixins.box-shadow-nav
    
.nav-links-container
    @include mixins.flex-gap
    width: 100vw

.mobile-nav, .footer-container
    @include mixins.container-full-width

.mobile-nav
    @include mixins.flex-column-center

.nav-button-toggle-icon
    display: flex
    justify-content: flex-end

.menu-toggle-button, .social-media-icon, .accordion-icon
    width: 1.5rem

    :hover
        filter: variables.$filter-yellow

.button-mobile-navbar
    @include mixins.flex-space-between
    padding: 1rem

.mobile-nav-container
    display: none

.mobile-nav-menu
    @include mixins.flex-column
    background-color: variables.$light-color
    gap: 2rem
    padding: 1rem 0
    z-index: 4
    position: relative

.menu-list li 
    display: inline-block
    padding: 0

    a 
        padding: 0.5em 0
        position: relative
        color: variables.$dark-color

    :focus
        color: variables.$yellow

    :before,
    :after 
        position: absolute
        -webkit-transition: all 0.35s ease
        transition: all 0.35s ease

    :before 
        bottom: 0
        display: block
        height: 3px
        width: 0%
        content: ""
        background-color: variables.$yellow

    :after 
        left: 0
        top: 0
        padding: 0.39em 0
        position: absolute
        content: attr(data-hover)
        color: variables.$light-grey
        white-space: nowrap
        max-width: 0%
        overflow: hidden

    :hover:before,
    .current a:before 
        opacity: 1
        width: 100%

    :hover:after,
    .current a:after 
        max-width: 100%

.social-media-container
    @include mixins.flex-center
    gap: 1rem
    background-color: variables.$light-color    

.social-media-container, .footer-copyright
    @include mixins.flex-padding

.accordion-container
    position: fixed
    right:  0
    z-index: 1
    background-color: variables.$red
    padding: 0.5rem   
    border-radius: 10px 0 0 10px
  
    .accordion-parent
        @include mixins.flex-space-between
        padding: inherit
        position: relative

        .accordion-text     
            h2
                transition: all linear 0.3s 
                color: variables.$light-color   
                margin-left: 1rem
                font-size: clamp(1.2rem, 5vw, 1rem)

.tabs-images-container
    @include mixins.flex-space-between-top
    gap: 2rem

    .tabs-content
        @include mixins.flex-column-gap
        border-radius: 5px
        width: 50vw
        padding: 2rem        

        .tabs-text  
            height: 60dvh
            border-radius: 5px         

.footer-container
    @include mixins.flex-space-between-top
    flex-flow: row wrap
    flex-shrink: 1
    background-color: variables.$light-color  
    padding: 2rem
    color: variables.$light-color
    margin-top: 3rem

    .footer-list
        margin: 0.6rem 0
        color: variables.$light-grey 

    .footer-list li, h1
        font-size: variables.$font-size-md
        list-style-type: none

.footer-mobile
    display: none

    .footer-mobile-list li 
        font-size: variables.$font-size-md

    .footer-mobile-list
        margin: 0

.footer-copyright-container
    @include mixins.flex-container-full-width

.footer-copyright
    @include mixins.flex-column-center

.search-container
    @include mixins.flex-column-center
    margin-top: 10rem
    width: 45vw  
    position: relative

    input
        @include mixins.input-button
        width: 45vw

    .search-result
        @include mixins.input-button
        width: inherit
        background-color: white
        display: flex
        flex-direction: column
        gap: 1rem
        box-shadow: 0px 0px 8px #ddd
        border-radius: 10px
        margin-top: 2.7rem
        max-height: 300px
        overflow-y: scroll 
        position: absolute 
        z-index: 2

        :hover
            color: variables.$light-grey

.tutorials-training-container, .card-parent
    margin-top: 10rem  

.tabs-container, .tutorials-training-container, .card-parent
    @include mixins.flex-wrap-space-around
    width: 100vw

    .cards, .tutorials-training-content
        @include mixins.box-shadow-nav
        width: 45vw 
        border-radius: 10px 
        margin: 1.5rem 0
        font-family: variables.$font-family-secondary 

        .tab-content-text-title, .tutorials-training-content-title  
            @include mixins.flex-align-justify-center
            padding: 1.5rem 0 1rem 1.5rem

        .tab-content-img, .tutorials-training-content-title img, .tabs img
            width: 30px           

    .tab-content-text, .tutorials-training-content p
        padding: 0.8rem 0.8rem 0.8rem 3rem  

        h1, ul li
            @include mixins.text-alignment-md   

        .tab-content-subProduct
            width: inherit
            padding: 1rem 0   

.tutorial-training-content-image
    width: inherit
    display: flex
    justify-content: center

    img
        width: 30%
        padding: 1rem 0

.tutorials-training-content:hover
    background-color: variables.$yellow

.page-under-construction 
    @include mixins.flex-center
    margin-top: 10rem    

    .page-under-construction-image
        margin: 3rem
    
.return-button  
    margin: 0 0 0 2rem
    position: absolute
    z-index: 4

    :hover
        background-color: variables.$yellow
    
    button
        @include mixins.flex-center
        gap: 1rem
        padding: 0.5rem 
        border-radius: 10px
        background-color: transparent
        font-size: 1rem
        margin-top: -2rem  

.contact-container
    margin-top: 10rem

    .contact-tabs 
        img
            width: 30px

    .panels-contact
        @include mixins.flex-center

    .contact-tab-content            
        @include mixins.flex-column-center
        justify-content: space-between
        min-height: 20rem
        background-color: variables.$ice
        width:inherit
        padding: 2rem
        margin-top: 2rem
        border-radius: 10px


.contact-tab-content-button
    @include mixins.flex-center
    gap: 2rem
    width: fit-content
    border: 1px solid variables.$yellow
    color: variables.$dark-color
    background-color: variables.$light-color
    padding: 1rem
    border-radius: 10px        

.contact-tab-content-button:hover
    background-color: variables.$yellow 

.btn
    @include mixins.flex-center
    gap: 1rem

// Media Queries

@media(min-width: variables.$breakpoint-xxl)
    .logo-full 
        max-width: 10vw
        padding: 1rem

    .menu-list:after
        padding: 0.41em 0        
            

@media(max-width: variables.$breakpoint-lg)
    .navbar-container, .mobile-nav-container
        @include mixins.flex-space-between 

        width: 100vw
        img
            width: 120px

    .nav-links-container, .logo-full, .social-media-full-nav, .footer-desktop
        display: none

    .tabs-images-container
        @include mixins.flex-column-reverse
        
        .tabs-content
            width: 90vw

    .footer-mobile
        display: block

    .tabs-container, .tutorials-training-container
        @include mixins.flex-column-center

        .cards, .tutorials-training-content
            width: 90vw

@media(min-width: 992px) and (max-width: 1057px)
    .nav-links-container
        display: flex
        gap: 2.9rem

@media(max-width: 781px)
    .contact-tab-content-button
        margin-top: 1rem

@media(max-width: variables.$breakpoint-sm)
    .contact-tab-content-button
        min-width: 100%

        .content-icon
            display: none

        button
            font-size: clamp(1rem, 5vw, 1.2rem)

    


    

    
