@use 'variables'

@mixin container-full-width
    width: 100vw

@mixin flex-container-full-width
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

@mixin flex-center
    display: flex
    justify-content: center
    align-items: center

@mixin flex-space-between
    display: flex
    justify-content: space-between
    align-items: center

@mixin flex-space-between-top
    display: flex
    justify-content: space-between

@mixin flex-gap
    display: flex
    gap: 4rem
    align-items: center
    justify-content: center

@mixin flex-space-evenly
    display: flex
    justify-content: space-evenly
    align-items: center

@mixin flex-space-around
    display: flex
    justify-content: space-around
    align-items: center

@mixin flex-wrap-space-around
    display: flex
    justify-content: space-around
    width: 100vw       
    flex-wrap: wrap
    gap: 1rem 

@mixin flex-column
    display: flex
    flex-direction: column

@mixin flex-column-center
    display: flex
    flex-direction: column
    align-items: center

@mixin flex-column-gap
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 4rem

@mixin flex-column-reverse
    display: flex
    flex-direction: column-reverse
    align-items: center


@mixin flex-align-justify-center
    display: flex
    gap: 2rem
    align-items: center
    justify-items: center
    
@mixin text-alignment-md
    font-family: variables.$font-family-secondary
    font-weight: variables.$font-weight-secondary
    font-style: normal
    font-size: clamp(1rem, 5vw, 1.2rem)
    line-height: 2rem
    text-align: left

@mixin flex-padding
    display: flex
    padding: 1rem

@mixin box-shadow-nav
    -webkit-box-shadow: 0px 0px 8px #cccccc
    box-shadow: 0px 0px 8px #cccccc

@mixin input-button
    border: 1px solid grey
    border-radius: 5px 
    padding: 0.5rem








    