$font-family-primary: "Rajdhani", sans-serif
$font-weight-primary: 600
$font-family-secondary: "Hind Siliguri", sans-serif
$font-weight-secondary: 300
$font-size-sm: 1rem
$font-size-md: 1.2rem
$font-size-bg: 1.5rem
$font-size-h1: 2rem
$dark-color: #000000
$light-color: #FFFFFF
$light-grey: rgb(146, 146, 146)
$ice: rgba(246, 246, 246, 0.563)
$red: #FF0000
$yellow: rgb(255, 153, 0)
$yellow-hover: rgba(255, 153, 0, 0.204)
$blue: #0A2473 
$breakpoint-xxl: 1400px
$breakpoint-xl: 1200px
$breakpoint-lg: 992px
$breakpoint-md: 768px
$breakpoint-sm: 576px
$breakpoint-xs: 382px
$filter-yellow: brightness(0) saturate(100%) invert(68%) sepia(20%) saturate(7137%) hue-rotate(360deg) brightness(102%) contrast(106%)
$border-color: #8f8f8f